
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapGetters } from "vuex";



export default {
	data() {
		return {
			sliderIndexSwiper: null,
			paginationInit: false
		}
	},
	mounted() {
		// console.log(this.settings)
		// AOS.init({
		// 	once: true
		// })
		// setTimeout(() => {
		this.sliderIndexSwiper = new swiper.default('.slider-index .swiper.swiper--index.swiper--maiSlider', {
			modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
			loop: true,
			// loopedSlides: 4,
			// autoplayDisableOnInteraction: false,
			// autoplay: true,
			slidesPerView: 1,
			// autoHeight: true,
			height: 800,
			// watchSlidesProgress: true,
			// scrollbar: false,
			// speed: 1800,
			autoplay: false,
			enabled: true,
			// on: {
			// sliderMove: this.sliderMove,
			// slideChangeTransitionStart: this.slideChangeTransitionStart
			// },
			pagination: {
				el: '.swiper-pagination.swiper-pagination--index',
				clickable: 'true',
				type: 'bullets',
				// renderBullet: function (index, className) {
				// 	return '<span class="' + className + '">' + '<span class="swiper-pagination-bar"></span>' + '<span class="swiper-pagination-progress"></span>' + '</span>';
				// },
			},
			navigation: {
				nextEl: '.swiper-button-next--index',
				prevEl: '.swiper-button-prev--index',
			},
			on: {
				afterInit: (value) => {
					this.paginationInit = true
				}
			}
		})
		// }, 100)


	},
	methods: {
		sliderMove() {
			document.querySelectorAll('.swiper-slide-next .slider-index__media').forEach((val) => {
				// val.style.display = 'none'
				// val.classList.remove("aos-init", "aos-animate");
				// AOS.init({
				// 	once: true
				// })
				// val.style.display = 'block'
			})
			document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
				val.style.display = 'none'
				val.classList.remove("aos-init", "aos-animate");
				val.style.display = 'block'
				val.classList.add("aos-init", "aos-animate");
			})
			// AOS.init()
		},

	},
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
		slides() {
			return [

				// {
				// 	id: 2024,
				// 	heading: `Новогодние скидки и подарки в <span>АЦ "Авангард"!</span>`,
				// 	content: `Только до 15.01.2024 г.`,
				// 	link: '/installments',
				// 	button: 'ОСТАВИТЬ ЗАЯВКУ',
				// 	image: {
				// 		x1: require('~/assets/img/slider-main/back-new-year@x1.webp'),
				// 		x2: require('~/assets/img/slider-main/back-new-year@x2.webp')
				// 	}
				// },
				{
					id: 5,
					heading: 'ВЫГОДНЫЙ АВТОКРЕДИТ <br>В АЦ ВЗЛЁТКА ОТ <span>4.9%</span>',
					content: `Скидка ${this.settings.sale_credit} при покупке автомобиля${this.$device.isMobile?'<br>':' '}в кредит`,
					link: '/credit',
					button: 'ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ',
					image: {
						x1: require('~/assets/img/slider-main/back-5@x1.webp'),
						x2: require('~/assets/img/slider-main/back-5@x2.webp')
					}
				},
				// {
				// 	id: 1,
				// 	heading: `ПАРТНЕРСКАЯ <br> ПРОГРАММА`,
				// 	content: `Сберавто и Автолидер`,

				// 	// heading: `АFВТОКРЕДИТ ОТ ${this.settings.credit_percent}`,
				// 	// content: `Со скидкой ${this.settings.sale_credit}  ₽`,
				// 	link: '/credit',
				// 	image: require('~/assets/img/slider-main/back-1.webp')
				// },

				{
					id: 3,
					// heading: `СберАвто и АвтоЛидер`,
					heading: `Автокредит СберАвто`,

					content: `автокредит на специальных условиях`,
					link: '/cars',
					button: 'ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ',
					image: {
						x1: require('~/assets/img/slider-main/back-3@x1.webp'),
						x2: require('~/assets/img/slider-main/back-3@x2.webp')
					}
				},
				{
					id: 4,
					heading: 'МГНОВЕННЫЙ ВЫКУП',
					content: `высокая оценка. выплата в день обращения`,
					link: '/buyout',
					button: 'ОСТАВИТЬ ЗАЯВКУ',
					image: {
						x1: require('~/assets/img/slider-main/back-4@x1.webp'),
						x2: require('~/assets/img/slider-main/back-4@x2.webp')
					}
				},

				{
					id: 6,
					heading: 'TRADE-IN',
					content: `выгода ${this.settings.sale_tradein}`,
					link: '/exchange',
					button: 'ОСТАВИТЬ ЗАЯВКУ',
					image: {
						x1: require('~/assets/img/slider-main/back-6@x1.webp'),
						x2: require('~/assets/img/slider-main/back-6@x2.webp')
					}
				},
				{
					id: 7,
					heading: `АВТОКРЕДИТ ОТ <span>${this.settings.credit_percent}</span>`,
					content: `первый взнос от 0 ₽`,
					link: '/credit',
					button: 'ОНЛАЙН КАЛЬКУЛЯТОР',
					image: {
						x1: require('~/assets/img/slider-main/back-7@x1.webp'),
						x2: require('~/assets/img/slider-main/back-7@x2.webp')
					}
				},
				{
					id: 8,
					heading: `РАССРОЧКА <span>${this.settings.installment_percent}</span>`,
					content: `покупка без переплат от Совкомбанк`,
					link: '/installments',
					button: 'ОСТАВИТЬ ЗАЯВКУ',
					image: {
						x1: require('~/assets/img/slider-main/back-8@x1.webp'),
						x2: require('~/assets/img/slider-main/back-8@x2.webp')
					}
				},
				{
					id: 9,
					heading: `подарок на выбор:`,
					content: `КАСКО, зимняя резина или аксессуар для автомобиля`,
					link: '/credit',
					button: 'ОСТАВИТЬ ЗАЯВКУ',
					image: {
						x1: require('~/assets/img/slider-main/back-9@x1.webp'),
						x2: require('~/assets/img/slider-main/back-9@x2.webp')
					}
				},




			]
		}
	}
}
